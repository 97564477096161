import React, {useEffect, useState} from "react"
import Template from "./Template"
import {useActions} from "../redux/actions";
import * as CallRecordingsActions from "../redux/actions/callRecordings";
import * as practicesActions from '../redux/actions/practice';
import {pageSizeArray} from '../constants';

import {Dropdown, Grid, Input, Button, Pagination } from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import HelpMark from "./help/HelpMark";
import moment from "moment";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

const CallRecordings: React.FC<any> = (props) => {
    const initialState: any = {
        activePage: 0,
        totalPages: 0,
        selectedPageSize: 10,
        selectedPractice: false,
        selectedDate: false,
        search: '',
        practice: '',
        practices: [],
        campaigns: [],
        allCampaigns: []
    }

    const callRecordingsActions = useActions(CallRecordingsActions);
    const practicesAction = useActions(practicesActions);
    const [state, setStates] = useState(initialState);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [selectionComplete, toggleSelectionComplete] = useState(false);

    useEffect(() => {
        practicesAction.getPractices().then(loadPractices)
    }, []);
    const loadPractices = (practices: any[]) => {

        const orderPractices = practices ? practices.sort((a: any, b: any) => a.practice.localeCompare(b.practice)) : []
        let practiceOptions: any = orderPractices.map((practice, key) => ({
            text: practice.practice,
            value: practice.practice,
            key
        }));
        practiceOptions = [
            {text: 'All', value: 'All', key: practiceOptions.length + 1},
            ...practiceOptions
        ];

        callRecordingsActions.getCallDirectory().then((items: any) => {
            setStates({...state, practices: practiceOptions,allCampaigns: items, campaigns: items})
        })
    }


    const fetchCallDates = (_e: any, {value}: any) => {
        setStates({...state, selectedPractice: value});
    }

    const changeCampaingsByPractice = (_e: any, {value}: any) => {
        const search = value.split(' ');

        if (value === 'All') {
            setStates({...state, practice: value, campaigns: state.allCampaigns})
            return;
        }
        const filtered = state.allCampaigns.filter((item: any) => {
            return item.value.toLowerCase().includes(search[0].toLowerCase())
        })

        setStates({...state, practice: value, campaigns: filtered})
        return;
    }

    const fetchRecords = () => {
        setStates({...state, activePage: 1});
        callRecordingsActions.fetchCallRecords(1, state.selectedPageSize, state.practice, state.selectedPractice, JSON.stringify({start: startDate, end: endDate, search: state.search}));
    }

    const downloadCallRecording = (key: any, title: React.ReactNode) => {
        callRecordingsActions.downloadRecording(key, title)
            .then((res: any) => {
                const url = res.data;
                window.open(url, "_blank");
            })
    }

    const searchText = (e: { target: any; }) => {
        const text = e.target.value;
        setStates({...state, search: text})
    };
    const generateTitle = (text: any, dateFolder: any) => {
        const strArr = text.split(' ');
        const time = strArr[strArr.length - 2].split('_').join(':')

        return (
            <div style={{display: 'flex'}}>
                <span style={{color: "white", backgroundColor: '#666666', padding: '2px 5px', borderRadius: '5px'}}>
                    <i className={`icon phone primaryColor`}></i>
                    {strArr[0]}
                </span>
                <span className={`ml10`} style={{marginRight: 'auto'}}>
                    <i className={`icon mail primaryColor`}></i>
                    {strArr[2]}
                </span>

                <span>
                    <i className={`icon ml10 calendar secondaryColor`}></i>
                    {moment(dateFolder, 'M_D_YYYY').format('DD MMM YYYY')}
                    <i className={`icon ml10 clock secondaryColor`}></i>
                    {time} {strArr[strArr.length-1].split('.')[0]}
                </span>
            </div>
        )
    }

    const handleDateChange = (date: any) => {
        if (!selectionComplete && !startDate) {
            setStartDate(date);
            return;
        }

        if (!selectionComplete && startDate && !endDate) {
            setEndDate(date);
            toggleSelectionComplete(true);
            return;
        }

        if (selectionComplete && startDate && endDate) {
            setStartDate(undefined);
            setEndDate(undefined);
            toggleSelectionComplete(false);
            return;
        }
    };

    const sameDay = (d1: any, d2: any) => {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    };
    const handleSelect = (date: any) => {
        if (!selectionComplete && startDate && !endDate && sameDay(date, startDate)) {
            handleDateChange(date);
        }
    };

    const onChangePageSize = (_e:any, {value}: any) => {
        setStates({...state, selectedPageSize: value});
    }

    const handlePagination = (e: any, { activePage }: any) => {
        setStates({...state, activePage });
        callRecordingsActions.fetchCallRecords(activePage, state.selectedPageSize, state.practice, state.selectedPractice, JSON.stringify({start: startDate, end: endDate, search: state.search}));
    }

    const inputValue = moment(`${startDate}`).format('MM/DD/YYYY') + ' - ' + moment(`${endDate}`).format('MM/DD/YYYY');

    const {callDates, callRecordings} = props;
    const { activePage } = state;

    let data = [];
    let totalPages: number = callRecordings.totalPages ?? 0;

    data = callRecordings?.data?.length > 0 ? callRecordings?.data?.sort((a: any, b: any) => moment(b.dateFolder, 'M_D_YYYY').valueOf() - moment(a.dateFolder, 'M_D_YYYY').valueOf()) : [];

    return (
        <Template activeLink="call-recordings">
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h3 className="float-left">Call Recordings <HelpMark pageId='1'/></h3>
                                <div className="topFilters flex items-end flex-wrap">
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Practice</label>
                                        <Dropdown
                                            search={true}
                                            className=' ui input mr10 mb15'
                                            name="location"
                                            placeholder="Practice"
                                            selection={true}
                                            options={state.practices}
                                            onChange={changeCampaingsByPractice}
                                            value={state.practice || ''}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Select Campaign</label>
                                            <Dropdown
                                                search={true}
                                                className=' ui input mr10 mb15'
                                                name="locations"
                                                placeholder="Campaign"
                                                selection={true}
                                                options={state.campaigns}
                                                onChange={fetchCallDates}
                                                value={state.selectedPractice || ''}
                                            />
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Choose Dates</label>
                                        <div className="datePicker">
                                            <DatePicker
                                                className='ui input mr10 mb15'
                                                selected={null}
                                                onChange={handleDateChange}
                                                onSelect={handleSelect}
                                                selectsEnd={Boolean(startDate)}
                                                startDate={startDate}
                                                endDate={endDate}
                                                placeholderText="Select Date Range"
                                                shouldCloseOnSelect={selectionComplete}
                                                //@ts-ignore
                                                value={!endDate ? new Date() : inputValue}
                                                isClearable={true}
                                            />

                                        </div>
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <label className="labelStyle displayBlock">Search</label>
                                        <Input
                                            focus
                                            className="mr10 mb15"
                                            placeholder="Search"
                                            icon={{name: 'search', circular: true, link: true}}
                                            onChange={searchText}
                                            value={state.search}
                                        />
                                    </div>
                                    <div className="filter d-InlineBlock float-left labelTop">
                                        <Button
                                            className='mb15'
                                            primary
                                            onClick={fetchRecords}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className="ui card" style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                <div className="content">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <strong>{callDates && callDates.length ? `Most recent recording date: ${callDates[1].text}` : ''}</strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div className="row">
                        <div className="col-12">
                            <Dropdown
                                compact
                                className=' ui input mr10 mb15 text-right'
                                name="pageSize"
                                placeholder="Page Size"
                                selection={true}
                                options={pageSizeArray}
                                onChange={onChangePageSize}
                                value={state.selectedPageSize || 10}
                            />
                        </div>
                    </div>
                    <table className="ui table table-striped celled table-hover customTable unstackable">
                        <thead>
                        <tr>
                            <th>
                                <div>Call Recordings</div>
                            </th>
                            <th>
                                <div>Practice</div>
                            </th>
                            <th>
                                <div>Size</div>
                            </th>
                            <th>
                                <div>Download</div>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {data && data.map((i: { text: React.ReactNode; size: number; value: any; dateFolder: any, practice: any }, index: string | number | undefined) =>
                            <tr key={index}>
                                <td>
                                    {generateTitle(i.text, i.dateFolder)}
                                </td>
                                <td>{i.practice}</td>
                                <td>{(i.size / 1024).toFixed(1)} KB</td>
                                <td>
                                    <Button primary
                                            onClick={() => downloadCallRecording(i.value, i.text)}>Download
                                    </Button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    <div className="row">
                        <div className="col-md-12">
                            <Pagination activePage={activePage}
                                        totalPages={totalPages}
                                        onPageChange={handlePagination}
                                        size='mini'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        callRecordings: state.callRecordings.callRecordings,
        callDirectory: state.callRecordings.callDirectory,
        callDates: state.callRecordings.callDates,
    };
}

export default connect(mapStateToProps)(CallRecordings);
