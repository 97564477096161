import { HeaderSchema } from "./model";

export const API_URL: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5006"
    : "https://apicallcenter.cdp.dental";

export interface MenuItem {
  pageId: number;
  name: string;
  icon: any;
  label: string;
  title: string;
  subMenus: Array<SubMenu>;
  role: Array<string>;
  hide?: boolean;
  customIcon?: string;
}
export interface SubMenu {
  pageId: number;
  name: string;
  icon: any;
  label: string;
  title: string;
  customIcon?: string;
  hide?: boolean;
  role?: Array<string>;
}
export const pageSizeArray: Array<any> = [
  { key: 1, text: '10', value: 10},
  { key: 2, text: '50', value: 50},
  { key: 3, text: '100', value: 100},
  { key: 4, text: '1000', value: 1000}
];

export const menusArray: Array<MenuItem> = [
  {
    pageId: 7,
    name: "dashboard",
    icon: "sliders vertical large",
    label: "DASHBOARD",
    title: "DASHBOARD",
    subMenus: [],
    role: [],
  },
  {
    pageId: 34,
    name: "Call Recordings",
    icon: "clipboard outline",
    label: "Call Recordings",
    title: "Call Recordings",
    subMenus: [],
    role: [],
    customIcon:"call-recording-icon",
  },
  {
    pageId: 39,
    name: "New Patient Origin",
    icon: "members-icon",
    label: "New Patient Origin",
    title: "New Patient Origin",
    subMenus: [],
    role: [],
    customIcon:"members-icon",
  },
  {
    pageId: 35,
    name: "Contact Data Report",
    icon: "clipboard outline",
    label: "Contact Data Report",
    title: "Contact Data Report",
    subMenus: [],
    role: [],
    customIcon:"contact-data-report-icon",
  },
  {
    pageId: 36,
    name: "Quality Index Report",
    icon: "clipboard outline",
    label: "Quality Index Report",
    title: "Quality Index Report",
    subMenus: [],
    role: [],
    customIcon:"quality-index-report-icon",
  },
  {
    pageId: 37,
    name: "Priority Status Report",
    icon: "clipboard outline",
    label: "Priority Status Report",
    title: "Priority Status Report",
    subMenus: [],
    role: [],
    customIcon:"priority-status-report-icon",
  },
  {
    pageId: 38,
    name: "Lead Data Report",
    icon: "clipboard outline",
    label: "Lead Data Report",
    title: "Lead Data Report",
    subMenus: [],
    role: [],
    customIcon:"lead-data-report-icon",
  },
  {
    pageId: 42,
    name: "Members Status Report",
    icon: "clipboard outline",
    label: "Members Status Report",
    title: "Members Status Report",
    customIcon:"members-icon",
    subMenus: [],
    role: [],
  },
  {
    pageId: 4,
    name: "under-construction",
    icon: "clipboard outline",
    label: "RTP 4",
    title: "RTP 4",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 5,
    name: "under-construction",
    icon: "clipboard outline",
    label: "EVA",
    title: "EVA",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 10,
    name: "under-construction",
    icon: "user outline",
    label: "ADMIN",
    title: "ADMIN",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 7,
    name: "under-construction",
    icon: "calendar alternate outline",
    label: "PP COLLECTION BY MONTH",
    title: "ADMIN",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 8,
    name: "under-construction",
    icon: "users",
    label: "PP PATIENT DETAILS",
    title: "PP PATIENT DETAILS",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 9,
    name: "under-construction",
    icon: "building  outline",
    label: "PP COLLECTIONS BY OFFICE",
    title: "PP COLLECTIONS BY OFFICE",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 6,
    name: "usage",
    icon: "pie chart",
    label: "USAGE",
    title: "USAGE",
    subMenus: [],
    role: ["Admin"],
  },
  {
    pageId: 11,
    name: "under-construction",
    icon: "clipboard outline",
    label: "DELETED APPOINTMENTS",
    title: "DELETED APPOINTMENTS",
    customIcon:"",
    subMenus: [
      {
        pageId: 39,
        name: "",
        icon: "clipboard outline",
        label: "By Group",
        title: "By Group",
      },
      {
        pageId: 40,
        name: "",
        icon: "clipboard outline",
        label: "Summary",
        title: "Summary",
      },
      {
        pageId: 41,
        name: "",
        icon: "clipboard outline",
        label: "Details",
        title: "Details",
      },
    ],
    role: [],
  },
];


export const membersTableHeaders: Array<HeaderSchema> = [
  { title:"Subscriber ID",databaseValue:"Subscriber_ID"},
  {title:"PatNum",databaseValue:"PatNum"},
 {title:"MCO",databaseValue:"MCO"},
  {title:"First Name",databaseValue:"First_Name"},
  {title:"Last Name",databaseValue:"Last_Name"},
  {title:"Practice",databaseValue:"Practice"},
  {title:"Address",databaseValue:"Address1"},
  {title:"City",databaseValue:"City"},
  {title:"State",databaseValue:"State"},
  {title:"Phone",databaseValue:"Phone"},
  {title:"Date Of Birth",databaseValue:"Date_of_Birth"},
  {title:"Age",databaseValue:""},
  {title:"Days Extracted",databaseValue:"Days_Extracted"},
  {title:"Notes",databaseValue:"notes"},
  {title:"Notes Time Stamp",databaseValue:"notestimestamp"},
  {title:"Status",databaseValue:"Status"},
  {title:"Result",databaseValue:"Result"},
  {title:"Last Vist",databaseValue:"LastVisit"},
  {title:"Next Appointment",databaseValue:"NextApt"},
  {title:"DB Entry Time",databaseValue:"entryTime"},
];
export interface OptionsType {
  key: string;
  value: string;
  text: string;
}
export const membersStatusFilters: Array<OptionsType> = [
  { value: "Dropped Patient", key: "Dropped Patient", text: "Dropped Patient" },
  {
    value: "Recare Future Appointment",
    key: "Recare Future Appointment",
    text: "Recare Future Appointment",
  },
  {
    value: "Recare No Future Appointment",
    key: "Recare No Future",
    text: "Recare No Future",
  },
  { value: "New Patient", key: "New Patient", text: "New Patient" },
  { value: "New Patient With Apt", key: "New Patient With Apt", text: "New Patient With Apt" },
  { value: "Duplicate SubID", key: "Duplicate SubID", text: "Duplicate SubID" },
];

export const memberResult = [
  "Blank",
  "Appointment Made",
  "Contact Made Call Back",
  "Contact Made Not Interested",
  "CM-DNC- Other Dentist",
  "CM-DNC- Moved",
  "Left message",
  "Broken",
  "Broken-Tx",
  "No answer",
  "Phone number issues",
  "Excessive Broken",
  "DNC- Other Dentist",
  "DNC- Moved",
  "DNC-Notes",
  "Transfer",
  "Reschedule",
  "Confirm"
];

export const membersResultFilters = memberResult.map((item) => {
  return {
    key: item,
    value: item,
    text: item,
  };
});


export const brands = [
  { text: "All", value: "all", key: "All" },
  { text: "SMD", value: "SMD", key: "SMD" },
  { text: "FSD", value: "FSD", key: "FSD" },
  { text: "OTD", value: "OTD", key: "OTD" },
  { text: "3CD", value: "3CD", key: "3CD" },
];

export const mcoOptionsList = [
  { text: "Denta", value: "denta", key: "denta" },
  { text: "MCNA", value: "mcna", key: "mcna" },
  { text: "UHC", value: "uhc", key: "uhc" },
  { text: "TMHP", value: "tmhp", key: "tmhp" },
];

export const drPracticeListOptions = [
  { text: "1 Dr", value: "1", key: "1" },
  { text: "2 Dr", value: "2", key: "2" },
  { text: "Denovo", value: "3", key: "3" },
];

export const DifferPracticeNames = {
  montana: "epmontana",
  nolana: "mcallen",
  carrier: "grandprairie",
  laspalmas: "sanantonio",
  zaragosa: "epzaragosa",
};

export const PracticeInfoTimeArray = [
  { value: "0", text: "CLOSED" },
  { value: "21600000", text: " 6:00 AM" },
  { value: "22500000", text: " 6:15 AM" },
  { value: "23400000", text: " 6:30 AM" },
  { value: "24300000", text: " 6:45 AM" },
  { value: "25200000", text: " 7:00 AM" },
  { value: "26100000", text: " 7:15 AM" },
  { value: "27000000", text: " 7:30 AM" },
  { value: "27900000", text: " 7:45 AM" },
  { value: "28800000", text: " 8:00 AM" },
  { value: "29700000", text: " 8:15 AM" },
  { value: "30600000", text: " 8:30 AM" },
  { value: "31500000", text: " 8:45 AM" },
  { value: "32400000", text: " 9:00 AM" },
  { value: "33300000", text: " 9:15 AM" },
  { value: "34200000", text: " 9:30 AM" },
  { value: "35100000", text: " 9:45 AM" },
  { value: "36000000", text: " 10:00 AM" },
  { value: "36900000", text: " 10:15 AM" },
  { value: "37800000", text: " 10:30 AM" },
  { value: "38700000", text: " 10:45 AM" },
  { value: "39600000", text: " 11:00 AM" },
  { value: "40500000", text: " 11:15 AM" },
  { value: "41400000", text: " 11:30 AM" },
  { value: "42300000", text: " 11:45 AM" },
  { value: "43200000", text: " 12:00 PM" },
  { value: "44100000", text: " 12:15 PM" },
  { value: "45000000", text: " 12:30 PM" },
  { value: "45900000", text: " 12:45 PM" },
  { value: "46800000", text: " 1:00 PM" },
  { value: "47700000", text: " 1:15 PM" },
  { value: "48600000", text: " 1:30 PM" },
  { value: "49500000", text: " 1:45 PM" },
  { value: "50400000", text: " 2:00 PM" },
  { value: "51300000", text: " 2:15 PM" },
  { value: "52200000", text: " 2:30 PM" },
  { value: "53100000", text: " 2:45 PM" },
  { value: "54000000", text: " 3:00 PM" },
  { value: "54900000", text: " 3:15 PM" },
  { value: "55800000", text: " 3:30 PM" },
  { value: "56700000", text: " 3:45 PM" },
  { value: "57600000", text: " 4:00 PM" },
  { value: "58500000", text: " 4:15 PM" },
  { value: "59400000", text: " 4:30 PM" },
  { value: "60300000", text: " 4:45 PM" },
  { value: "61200000", text: " 5:00 PM" },
  { value: "62100000", text: " 5:15 PM" },
  { value: "63000000", text: " 5:30 PM" },
  { value: "63900000", text: " 5:45 PM" },
  { value: "64800000", text: " 6:00 PM" },
  { value: "65700000", text: " 6:15 PM" },
  { value: "66600000", text: " 6:30 PM" },
  { value: "67500000", text: " 6:45 PM" },
  { value: "68400000", text: " 7:00 PM" },
  { value: "69300000", text: " 7:15 PM" },
  { value: "70200000", text: " 7:30 PM" },
  { value: "71100000", text: " 7:45 PM" },
  { value: "72000000", text: " 8:00 PM" },
];
export const TimezoneArray = [
  { value: "america/chicago", text: "america/chicago" },
  { value: "america/denver", text: "america/denver" },
  { value: "america/phoenix", text: "america/phoenix" },
  { value: "america/los_angeles", text: "america/los_angeles" },
  { value: "america/anchorage", text: "america/anchorage" },
  { value: "america/new_york", text: "america/new_york" },
];
export const allowDeleteUserId = [163, 1710, 791, 1189, 1180];
export const udrPermissions = [
  { text: "All", value: "All", key: "All" },
  { text: "Admin", value: 1, key: "Admin" },
  { text: "Dentist", value: 2, key: "Dentist" },
  { text: "Orthodontics", value: 3, key: "Orthodontics" },
  { text: "Oral Surgeon", value: 4, key: "Oral Surgeon" },
  { text: "Traveling Team", value: 5, key: "Traveling Team" },
  { text: "Credentialing", value: 6, key: "Credentialing" },
];

export const booleanOptionsArrays = [
  { value: "0", text: " No" },
  { value: "1", text: " Yes" },
];

export const specialityOptions = [
  { key: "ORALSURGERY", text: "ORALSURGERY", value: "Oral Surgery" },
  { key: "GENERAL", text: "GENERAL", value: "General" },
  { key: "ORTHO", text: "ORTHO", value: "Ortho" },
];

export const myCDPOpenIssuesApplicationList =[
  "Members"
];

const McoList = ['DENTA','MCNA','UHC'];

export const McoListOptions = McoList.map((item) => {
  return {
    key: item,
    value: item,
    text: item,
  };
});

export const appName = "call_center.cdp.dental";
export const supportMailList = "support@cdp.dental,feedback.tools@cdp.dental";

export const setCookie = (cname: string,cvalue: string,exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = async() => {
  const cookie1 = 'cdp-cookie';
  document.cookie = cookie1 + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path='/';"
};

export const membersCrons=['Fetch From Open dental', 'member'];

export const dentistListUrl='Members/getDentistList';

export const cronsListUrl='Members/crons';

export const openIssuesListUrl='Members/getOpenIssues';

export const HelpPages: any = {
  1: { name: "" },
}
